<template>
  <div id="app">
    <div class="nav">
      <div :class="{ navItem: true, active: isCurrent('home') }" @click="jump('home')">网站首页</div>
      <div :class="{ navItem: true, active: isCurrent('welcome') }" @click="jump('welcome')">会议信息</div>
      <div :class="{ navItem: true, active: isCurrent('committee') }" @click="jump('committee')">嘉宾介绍</div>
      <img class="logo" src="./assets/logo.png" alt="" />
      <div :class="{ navItem: true, active: isCurrent() }" @click="jump()">会议日程</div>
      <div :class="{ navItem: true, active: isCurrent() }" @click="jump('gallery')">云端展厅</div>
      <div :class="{ navItem: true, active: isCurrent('contact') }" @click="jump('contact')">联系我们</div>
    </div>
    <div class="bannerWp">
      <img class="banner" src="./assets/banner.png" alt="" />
    </div>
    <router-view/>
  </div>
</template>
<script>
export default {
  methods: {
    jump (where) {
      this.$router.push({ name: where || 'plzWait' })
    },
    isCurrent (value) {
      return this.$route.name === value
    }
  },
}
</script>
<style lang="less">
html,body {
  width: 100%;
  height: 100%;
}

* {
  box-sizing: border-box;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}
.nav {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  .logo {
    width: 254px;
    height: 67px;
    margin: 0 30px;
  }

  .navItem {
    font-size: 24px;
    color: #4E4E4E;
    font-weight: 500;
    margin-right: 20px;
    cursor: pointer;

    &.active {
      color: #0F2767;
    }

    &:nth-last-of-type(1) {
      margin-right: 0;
    }
  }
}

.bannerWp {
  line-height: 0;

  .banner {
    width: 100%;
  }
}

</style>
