<template>
  <div class="container">
    <Base>
      <div class="main">
        <Row gutter="10" style="margin-bottom: 14px">
          <Col style="width: 260px">
            <div>
              <div class="gradientTitle">
                <div>2022.10.24-10.29</div>
                <div>中国·上海</div>
              </div>
              <img src="../assets/people.jpg" style="width: 100%; height: 290px; object-fit: cover" />
            </div>
          </Col>
          <Col style="width: 407px">
            <div class="gradientTitle">
              主席致辞
            </div>
            <div class="welcome">
              尊敬的各位同道：
              <p>
                岁月不居，时光如流。过去的几十年，我们紧随国家政策和党的要求，在神经领域不断探索和创新。今年正值建党100年之际，中国神经病学领域取得了显著进展，随着影像技术及分子生物学技术持续发展，AI识别及诊断与神经领域技术的融合，新的基因治疗技术出现与应用等，是的神经疾病领域发展有了更多的机遇，但如何将创新技术与科技手段应用于临床，造福患者，从而推动领域的发展，也是我们每个人未来要面临的挑战。
              </p>
              <div class="more" @click="jump('welcome')">
                查看更多
              </div>
            </div>
          </Col>
          <Col style="width: 385px">
            <div class="gradientTitle">
              大会重要日期
            </div>
            <div class="schedule">
              <img src="../assets/schedule.png" alt="" />
            </div>
          </Col>
        </Row>
        <Row gutter="5" style="margin-bottom: 15px">
          <Col style="width: 432px">
            <div class="maskArea" @click="jump()">
              <img src="../assets/live.png" alt="云上中国·点我观看直播" />
              <img class="play" src="../assets/play.png" alt="播放" />
              <div class="mask">云上中国·点我观看直播</div>
            </div>
          </Col>
          <Col style="width: 312px">
            <div class="maskArea" @click="jump('gallery')">
              <img src="../assets/gallery.png" alt="云端展厅" />
              <div class="mask">云端展厅</div>
            </div>
          </Col>
          <Col style="width: 312px">
            <div class="maskArea" @click="jump2021()">
              <img src="../assets/review.png" alt="CNF2021回顾" />
              <div class="mask">CNF2021回顾</div>
            </div>
          </Col>
        </Row>
        <Row gutter="5">
          <Col span="8">
            <div class="contact">
              <p class="contactBlue">CNF2022</p>
              <p class="contactBlue">上海卒中学会</p>
              <p>地址:上海市南京西路1486号东海广场3号楼908室</p>
              <p>邮箱:SSA@shanghaistroke.org.cn</p>
              <p>电话:021-62299150</p>
              <p>邮编:200040</p>
              <div class="divider" />
              <div class="qrcodeWp">
                <img class="qrcode" src="../assets/qrcode.png" alt="二维码" />
                <div class="contactBlue">扫描二维码</div>
                <div class="contactBlue">关注上海卒中学会公众号</div>
              </div>
            </div>
          </Col>
          <Col span="8" style="overflow: hidden">
            <div class="gradientTitle">
              CNF2021 精彩瞬间
            </div>
            <div class="swiperWp">
              <div class="swiperOverFlow">
                <div class="swiper" id="swiper1">
                  <div class="swiper-wrapper">
                    <div class="swiper-slide"><img class="swiperImage" src="../assets/swiperImage/1.f13baad.jpg" /></div>
                    <div class="swiper-slide"><img class="swiperImage" src="../assets/swiperImage/2.94dc430.jpg" /></div>
                    <div class="swiper-slide"><img class="swiperImage" src="../assets/swiperImage/3.743ba68.jpg" /></div>
                    <div class="swiper-slide"><img class="swiperImage" src="../assets/swiperImage/4.82030e2.jpg" /></div>
                    <div class="swiper-slide"><img class="swiperImage" src="../assets/swiperImage/5.252b9e8.jpg" /></div>
                    <div class="swiper-slide"><img class="swiperImage" src="../assets/swiperImage/6.9b9df52.jpg" /></div>
                    <div class="swiper-slide"><img class="swiperImage" src="../assets/swiperImage/7.c082775.jpg" /></div>
                    <div class="swiper-slide"><img class="swiperImage" src="../assets/swiperImage/8.62b80ef.jpg" /></div>
                    <div class="swiper-slide"><img class="swiperImage" src="../assets/swiperImage/9.28284ee.jpg" /></div>
                    <div class="swiper-slide"><img class="swiperImage" src="../assets/swiperImage/10.e690847.jpg" /></div>
                    <div class="swiper-slide"><img class="swiperImage" src="../assets/swiperImage/11.3b52871.jpg" /></div>
                    <div class="swiper-slide"><img class="swiperImage" src="../assets/swiperImage/12.4b07888.jpg" /></div>
                    <div class="swiper-slide"><img class="swiperImage" src="../assets/swiperImage/13.8133185.jpg" /></div>
                    <div class="swiper-slide"><img class="swiperImage" src="../assets/swiperImage/14.02d2c92.jpg" /></div>
                    <div class="swiper-slide"><img class="swiperImage" src="../assets/swiperImage/15.f0e5768.jpg" /></div>
                    <div class="swiper-slide"><img class="swiperImage" src="../assets/swiperImage/16.741f867.jpg" /></div>
                    <div class="swiper-slide"><img class="swiperImage" src="../assets/swiperImage/17.7bcd5e8.jpg" /></div>
                  </div>
                </div>
              </div>
              <img class="navigation prev" id="prev1" src="../assets/prev.png" alt="prev" />
              <img class="navigation next" id="next1" src="../assets/next.png" alt="next" />
            </div>
          </Col>
          <Col span="8" style="overflow: hidden">
            <div class="gradientTitle">
              CNF2021 视频集锦
            </div>
            <div class="videoWp">
              <video src="http://static-tiantan.mwcare.cn/10.29-31_30s%E8%8A%B1%E7%B5%AE%E9%AB%98%E6%B8%85.mp4" controls />
            </div>
          </Col>
        </Row>
      </div>
    </Base>
  </div>

</template>

<script>
import { Col, Row } from 'vant'
import { SwiperSlide } from "swiper/vue";
import Swiper, { EffectCoverflow, Navigation } from "swiper";
import Base from "@/components/Base";
import "swiper/css";
import "swiper/css/effect-coverflow";

export default {
  name: "Home",
  components: {
    Base,
    Col,
    Row,
    Swiper,
    SwiperSlide
  },
  methods: {
    jump2021() {
      window.open('http://2021.shanghaistroke-cnf.com')
    },
    jump (where) {
      this.$router.push({ name: where || 'plzWait' })
    }
  },
  mounted() {
    new Swiper("#swiper1", {
      effect: 'coverflow',
      loop: true,
      slidesPerView: 3,
      centeredSlides: true,
      modules: [Navigation, EffectCoverflow],
      coverflowEffect: {
        slideShadows: false
      },
      navigation: {
        prevEl: "#prev1",
        nextEl: "#next1",
      },
    });
    new Swiper("#swiper2", {
      effect: 'coverflow',
      loop: true,
      slidesPerView: 3,
      centeredSlides: true,
      modules: [Navigation, EffectCoverflow],
      coverflowEffect: {
        slideShadows: false
      },
      navigation: {
        prevEl: "#prev2",
        nextEl: "#next2",
      },
    });
  },
}
</script>

<style scoped lang="less">
.container {
  padding-bottom: 358px;

  .main {
    margin: 18px auto 0;
    width: 1300px;
    padding: 20px 115px 82px 111px;
    box-sizing: border-box;
    box-shadow: 0 2px 5px 0 rgba(119,149,203,0.49);
    background-color: rgba(255, 255, 255, 0.5);

    .gradientTitle {
      height: 60px;
      background: linear-gradient(-153deg, #4DA4FF, #74CDFF);
      box-shadow: 0 2px 5px 0 rgba(0,0,0,0.04);
      border-radius: 6px 6px 0 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      font-size: 20px;
      font-weight: 500;
      line-height: 27px;
    }

    .welcome {
      height: 290px;
      padding: 14px 20px 0 20px;
      border: solid 1px #E5EFFC;
      font-size: 14px;
      color: #727171;
      font-weight: 500;
      line-height: 24px;
      position: relative;
      border-radius: 0 0 6px 6px;

      p {
        text-indent: 28px;
        margin: 0;
      }

      .more {
        position: absolute;
        bottom: 12px;
        right: 23px;
        color: #2D64AE;
        cursor: pointer;
      }
    }

    .schedule {
      height: 290px;
      padding: 9px 18px 7px 21px;
      border: 1px solid #E5EFFC;
      border-radius: 0 0 6px 6px;

      & > img {
        width: 100%;
      }
    }

    .maskArea {
      height: 233px;
      position: relative;
      margin-right: 7px;
      display: inline-block;
      cursor: pointer;

      &:nth-last-of-type(1) {
        margin-right: 0;
      }

      & > img {
        width: 100%;
        height: 233px;
      }

      .mask {
        position: absolute;
        left: 0;
        bottom: 9px;
        width: 100%;
        height: 37px;
        background-color: rgba(0,0,0,0.7);
        font-size: 18px;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .play {
        position: absolute;
        width: 66px;
        height: 66px;
        top: 67px;
        left: 0;
        right: 0;
        margin: auto;
      }
    }

    .contact {
      border-radius: 6px;
      border: 1px solid #E5EFFC;
      padding: 17px 24px 14px 23px;

      p {
        margin: 0;
        color: #727171;
        font-size: 14px;
        line-height: 20px;
      }

      .contactBlue {
        color: #2D64AE;
        font-weight: bold;
      }

      .divider {
        width: 236px;
        height: 1px;
        background-color: #9B9B9B;
        margin: 12px 0 18px;
      }

      .qrcodeWp {
        font-size: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .qrcode {
          width: 84px;
          margin-bottom: 13px;
        }
      }
    }

    .swiperWp {
      padding: 12px 0;
      border: solid 1px #E5EFFC;
      border-radius: 0 0 6px 6px;
      position: relative;

      .swiperOverFlow {
        height: 233px;
        margin: 0 10px;
        overflow: hidden;

        .swiper {
          width: 650px;
          margin-left: -162px;

          .swiperImage {
            width: 100%;
            height: 232px;
            object-fit: cover;
          }
        }
      }

      .navigation {
        position: absolute;
        top: 80px;
        width: 18px;
        z-index: 3;
        cursor: pointer;

        &.prev {
          left: 20px;
        }

        &.next {
          right: 20px;
        }
      }
    }

    .videoWp {
      padding: 12px;
      border: solid 1px #E5EFFC;
      border-radius: 0 0 6px 6px;
      display: flex;
      align-items: center;
      height: 259px;

      & video {

        width: 100%;
      }
    }

  }

}




</style>