<template>
  <Base class="container">
    <div class="waitWp">
      <el-row :gutter="24">
        <el-col :span="6"><img class="galley" src="../assets/gallery/adv1.png" @click="onPreview(1)"></el-col>
        <el-col :span="6"><img class="galley" src="../assets/gallery/adv12.png" @click="onPreview(12)"></el-col>
        <el-col :span="6"><img class="galley" src="../assets/gallery/adv2.png" @click="onPreview(2)"></el-col>
        <el-col :span="6"><img class="galley" src="../assets/gallery/adv3.png" @click="onPreview(3)"></el-col>
        
      </el-row>
      <el-row :gutter="24">
        <el-col :span="6"><img class="galley" src="../assets/gallery/adv4.png" @click="onPreview(4)"></el-col>
        <el-col :span="6"><img class="galley" src="../assets/gallery/adv5.png" @click="onPreview(5)"></el-col>
        <el-col :span="6"><img class="galley" src="../assets/gallery/adv6.png" @click="onPreview(6)"></el-col>
        <el-col :span="6"><img class="galley" src="../assets/gallery/adv13.png" @click="onPreview(13)"></el-col>
        
      </el-row>
      <el-row :gutter="24">
        <el-col :span="6"><img class="galley" src="../assets/gallery/adv7.png" @click="onPreview(7)"></el-col>
        <el-col :span="6"><img class="galley" src="../assets/gallery/adv8.png" @click="onPreview(8)"></el-col>
        <el-col :span="6"><img class="galley" src="../assets/gallery/adv9.jpg" @click="onPreview(9)"></el-col>
        <el-col :span="6"><img class="galley" src="../assets/gallery/adv11.png" @click="onPreview(11)"></el-col>
        
      </el-row>
      <el-row :gutter="24">
        <el-col :span="6"><img class="galley" src="../assets/gallery/adv10.jpg" @click="onPreview(10)"></el-col>
      </el-row>
      <el-image-viewer v-if="showViewer" :on-close="closeViewer" :url-list="srcList" />
    </div>
  </Base>
</template>

<script>
import Base from "@/components/Base";
import ElImageViewer from 'element-ui/packages/image/src/image-viewer';
export default {
  name: "gallery",
  components: {
    Base, ElImageViewer
  },
  data() {
    return {
    showViewer: false,
    url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
    srcList: [],
    picList: [
      {
        id: 1,
        images: [
          require('../assets/gallery/advD1.jpg')
        ]
      },
      {
        id: 2,
        images: [
          require('../assets/gallery/advD2-1.jpg'),require('../assets/gallery/advD2-2.jpg')
        ]
      },
      {
        id: 3,
        images: [
          require('../assets/gallery/advD3-1.jpg'),require('../assets/gallery/advD3-2.jpg'),require('../assets/gallery/advD3-3.jpg'),require('../assets/gallery/advD3-4.jpg')
        ]
      },
      {
        id: 4,
        images: [
          require('../assets/gallery/advD4-1.jpg'),require('../assets/gallery/advD4-2.jpg'),require('../assets/gallery/advD4-3.jpg'),require('../assets/gallery/advD4-4.jpg')
        ]
      },
      {
        id: 5,
        images: [
          require('../assets/gallery/advD5-1.png'),require('../assets/gallery/advD5-2.png')
        ]
      },
      {
        id: 6,
        images: [
          require('../assets/gallery/advD6-1.png'),require('../assets/gallery/advD6-2.png')
        ]
      },
      {
        id: 7,
        images: [
          require('../assets/gallery/advD7.jpg')
        ]
      },
      {
        id: 8,
        images: [
          require('../assets/gallery/advD8-1.jpg'),require('../assets/gallery/advD8-2.jpg'),require('../assets/gallery/advD8-3.jpg'),require('../assets/gallery/advD8-4.jpg')
        ]
      },
      {
        id: 9,
        images: [
          require('../assets/gallery/advD9.jpg')
        ]
      },
      {
        id: 10,
        images: [
          require('../assets/gallery/advD10.jpg')
        ]
      },
      {
        id: 11,
        images: [
        require('../assets/gallery/advD11-1.jpg'),require('../assets/gallery/advD11-2.jpg'),require('../assets/gallery/advD11-3.jpg'),require('../assets/gallery/advD11-4.jpg')
        ]
      },
      {
        id: 13,
        images: [
          require('../assets/gallery/advD13-1.jpg')
        ]
      }
    ]
    }
  },
  methods: {
    onPreview(ad) {
      this.showViewer = true
      this.srcList = []
      this.picList.forEach(item => {
        if(item.id === ad) {
          this.srcList = item.images
        }
      })
    },
    closeViewer() {
      this.showViewer = false
    }
  }
}
</script>

<style scoped lang="less">

.container {
  padding: 129px 0 ;

  .galley{
    box-shadow: 8px 6px 8px rgba(7, 124, 240, 0.5);
    border-radius: 10px;
    width: 293px;
    cursor: pointer;
  }
  .waitWp {
    width: 1302px;
    // height: 360px;
    // display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
  }

  .wait {
    width: 393px;
  }
}


</style>