<template>
  <div class="chairman">
    <div>
      <h1 class="articleTitle">大会执行主席</h1>
      <div class="intro">
        <div class="avatar">
          <img src="../../../assets/people.jpg" alt="" />
        </div>
        <div class="introText">
          <div class="subTitle">
            <span>董强</span>
            <span class="small">教授、主任医师、博士生导师</span>
          </div>
          <p>
            上海领军人才  上海市十佳公共卫生工作者<br />
            复旦大学附属华山医院神经内科主任  <br />
            国家神经疾病医学中心（华山）副主任<br />
            中华医学会神经病学分会副主任委员、中国卒中学会副会长<br />
            上海市医学会神经内科专委会主任委员、上海市医师协会神经内科医师分会会长<br />
            上海市神经系统疾病临床医学中心主任、上海市神经内科质控中心主任<br />
            上海卒中学会常务副会长<br />
          </p>
        </div>
      </div>
      <div class="intro">
        <div class="avatar">
          <img src="../../../assets/wangxin.jpg" alt="" />
        </div>
        <div class="introText">
          <div class="subTitle">
            <span>汪昕</span>
            <span class="small">教授，博士研究生导师 </span>
          </div>
          <p>
            国务院特殊津贴专家，上海领军人才，上海工匠<br />
            复旦大学附属中山医院党委书记<br />
            中华医学会神经病学分会常务委员，中国医师协会神经内科医师分会常务委员<br />
            上海市医学会神经内科专科分会候任主任委员<br />
          </p>
        </div>
      </div>
      <div class="intro">
        <div class="avatar">
          <img src="../../../assets/fandongsheng.jpg" alt="">
        </div>
        <div class="introText">
          <div class="subTitle">
            <span>樊东升</span>
            <span class="small">教授</span>
          </div>
          <p>
            北京大学第三医院神经内科主任<br />
            神经退行性疾病生物标志物研究及转化北京市重点实验室主任<br />
            国家卫健委突贡专家<br />
            现任《中华脑血管病杂志》主编<br />
            中华神经病学会北京分会候任主委<br />
            中华神经病学会肌电图与临床神经生理学组组长<br />
            中华预防医学会自由基医学会主委<br />
            中国心胸血管麻醉学会脑与血管分会主委<br />
            中国医促会神经病学分会副主委<br />
            中国微循环学会神经变性病分会副主委等<br />
            主要研究领域：神经退行性疾病及脑血管病
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Chairman"
}
</script>

<style scoped lang="less">
.chairman {
  background-color: rgba(255, 255, 255, 0.5);
  border: 1px solid #E5EBFC;
  padding: 0 20px 0 110px;
  text-align: center;

  .articleTitle {
    text-align: center;
    font-size: 30px;
    margin: 30px;
    color: #3660a9;
  }

  .intro {
    margin: 80px 0 200px;
    display: flex;
    align-items: flex-start;

    .avatar {
      width: 202px;
      height: 248px;

      & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }


    }

    .introText {
      text-align: left;
      margin-left: 20px;

      .subTitle {
        color: #3660a9;
        font-size: 25px;
        font-weight: bold;

        .small {
          font-size: 16px;
          margin-left: 6px;
        }
      }

      p {
        font-size: 15px;
        line-height: 26px;
        color: black;
      }
    }
  }
}
</style>