<template>
  <Base class="container">
    <div class="waitWp">
      <div class="pageBox">
          <div class="topCla">
              <div class="dateTop">{{yearMonth}}</div>
              <div class="dateBox">
                  <div class="dateItem" v-for="(item, index) in dateList" :key="index" @click="changeDate(item.startTime)">
                      <div class="dateCla" :class="date == item.day ? 'select' : ''">{{item.day}}</div>
                      <div class="pointCla" :class="date == item.day ? 'blPoint' : ''" />
                  </div>
              </div>
          </div>
          <div class="listPage">
              <van-empty v-if="list.length==0" description="暂无日程安排" />
              <van-collapse style="width: 100%; padding: 32px;" :value="activeName" accordion @change="changeMeeting" >
                  <van-collapse-item style="margin-bottom: 50px" v-for="(item,index) in list"  class="card" :key="index" :title="item.title" :name="item.id">
                      <template #title>
                        <div style="cursor: pointer; width: 100%;">
                            <div class="status">
                                <div class="status2" :class="item.state == 2 && 'status3'">
                                    <span class="icon" :class="'icon'+item.state"></span>
                                    <span class="textT" :class="classStatus[item.state]">{{statusName[item.state]}}</span>
                                </div>
                            </div>
                            <div class="title">{{item.name}}</div>
                            <div class="address mt2">{{item.address || ''}}</div>
                            <div class="address"  v-if="item.masterVo.length>4">主席：<span class="zhuchi" style="width:62vw">{{item.masters}}</span></div>
                            <template v-else-if="item.masters">
                                <div class="address is-array" >
                                    <div class="_title">主席：</div>
                                    <div class="flex1">
                                        <div class="zhuchi special" style="width:62vw" v-for="(item,index) in item.masterVo" :key="index">
                                            {{item.speakerName}}
                                            <span style="color:#666666">{{item.speakerHospital}}</span>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <div class="address" v-if="item.speakerVo.length>4" >主持：<span class="zhuchi">{{item.speakers}}</span></div>
                            <template v-else>
                                <div class="address is-array" >
                                    <div class="_title">主持：</div>
                                    <div class="flex1" >
                                        <div class="zhuchi special" style="width:62vw" v-for="(item,index) in item.speakerVo" :key="index">
                                            {{item.speakerName}}
                                            <span style="color:#666666">{{item.speakerHospital}}</span>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                      </template>
                      <div class="items">
                          <van-empty v-if="item.speakerList.length==0" description="暂无会议信息" />
                          <div v-for="(meeting,index) in item.speakerList" class="infoPage" :key="index">
                              <div class="infoBox">
                                <div class="pcitem">
                                  <div class="topBox">
                                      <span class="round"></span>
                                      <span class="textCla">{{meeting.startTime}}-{{meeting.endTime}}</span>
                                  </div>
                                  <div class="topicCla">
                                      <div class="textBlack" >{{meeting.topic}}</div>
                                  </div>
                                  <div v-if="meeting.speakerVo.length>4" class="w35"><span class="textBlack zhuchi" style="width: auto; width: 35%;text-align: center;">{{meeting.speakers}}</span></div>
                                  <template v-else>
                                      <div class="is-array w35" >
                                          <div class="flex1">
                                              <div class="zhuchi " style="width: auto" v-for="(item,index) in meeting.speakerVo" :key="index">
                                                  {{item.speakerName}}
                                              </div>
                                          </div>
                                      </div>
                                  </template>
                                  <div class="arrow" @click="toLive(meeting)">查看</div>
                                </div>
                                <div class="bottomBox" :class="index+1 < item.speakerList.length ? 'dushed' : ''" />
                              </div>
                          </div>
                      </div>
                  </van-collapse-item>
              </van-collapse>
          </div>
      </div>
    </div>
  </Base>
</template>

<script>
import Base from "@/components/Base";
  import req from '../../utils/request'
export default {
  name: "PlzWait",
  components: {
    Base
  },
    data() {
        return {
            activeName:'',
            list: [],
            statusName: ['', '未开始','直播中','已结束', '回放', ],
            classStatus: ['gridColor', ''],
            dateList: [],
            date: '18',
            yearMonth: ''
        };
    },
    metaInfo: {
        title: '日程安排'
    },
    mounted() {
        this.getList()
    },
    methods: {
        toLive(item) {
           if(item.jumpTripartite) {
            window.open(item.tripartiteUrl)
           }
        },
       getList(date) {
        this.activeName = ''
        const params = {
            date,
            projectId: '1577865580738805761'
        }
        req.get("/api/forum/listbyproject", {params}).then(({data: res}) =>{
            if (!date) {
                this.dateList = res.data
            }
            const list = res.data.filter(item => item.showCurrent)
            if (list.length) {
                this.list = list[0].detailVo.map(item => {
                    return {
                        ...item,
                        speakerList: []
                    }
                })
                this.date = list[0].day
                this.yearMonth = `${list[0].year}年${list[0].month}月`
            }
        }).catch(() => {
        })
       },
       async changeDate(item) {
            await this.getList(item)
            this.date = item
       },
       changeMeeting(id) {
            if(!id) {
                this.activeName = id
                return
            }
            const params = {
                forumId: id
            }
            req.get("/api/live/list", {params}).then(({data: res}) =>{
                const list = res.data || []
                this.list = this.list.map(item => {
                    if (item.id == id) {
                        item.speakerList = list
                    }
                    return item
                })
                this.activeName = id
            }).catch(() => {
            })
       }
    }
}
</script>

<style scoped lang="less">

.container {
  padding: 129px 0 ;

  .waitWp {
    width: 1302px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url('../assets/waitBC.png') center/contain no-repeat;
    margin: auto;
  }

  .wait {
    width: 393px;
  }
  .pageBox {
        width: 100%;
        height: 100%;
        background-color: #fff;
        margin-bottom: 100px;
    }
    .listPage {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;
        border: 2px solid #E5EBFC;
        border-top-width: 0;
    }
    .icon{
        width: 18px;
        height: 16px;
        display: inline-block;
    }
    .icon4{
        background:  url('../assets/icon/icon2.png') no-repeat center center;
        background-size: 100% 100%;
        margin-right: 0.3rem;
    }
    .icon1{
        background:  url('../assets/icon/icon1.png') no-repeat center center;
        background-size: 100% 100%;
        margin-right: 0.3rem;
    }
    .icon3{
        background:  url('../assets/icon/icon1.png') no-repeat center center;
        background-size: 100% 100%;
        margin-right: 0.3rem;
    }
    .icon5{
        background:  url('../assets/icon/icon3.png') no-repeat center center;
        background-size: 100% 100%;
        height: 1rem;
        width: 1rem
    }
    .icon2{
        background:  url('../assets/icon/icon4.gif') no-repeat center center;
        background-size: 100% 100%;
        margin-right: 0.3rem;
    }
    .title {
        width: 100%;
        font-size: 28px;
        font-weight: bold;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        margin-top: 30px;
        margin-bottom: 20px;
        line-height: 28px;
    }
    .mt2 {
        margin-top: 0.2rem;
    }
    .address {
        color: #373C43;
        font-size: 20px;
    }
    .is-array{
        display: flex;
    }
    .is-array  ._title{
        display: inline-block;
    }
    .is-array  .flex1{
        flex:1
    }
    .flex1 .zhuchi{
        width: 46vw;
    }
    .zhuchi {
        color: #12247F !important;
    }
    .status {
        border-bottom: 0.13vw solid #EEEEEE;
        padding-bottom: .5vw;
    }
    .status2 {
        display: flex;
        align-items: center;
    }
    .status3 {
        background-color: #ffd077;
        width: fit-content;
        border-radius: 2vw;
        padding: 0px 6px 1px 6px
    }
    .textT {
        padding-top: 4px;
        font-size: 18px;
    }
     .listPage /deep/ .van-collapse-item__title--expanded::after{
        display: none;
    }
     .listPage /deep/ .van-cell__right-icon{
        position: absolute;
        right: 10px;
        top: 20px;
    }
    .listPage /deep/ .van-collapse-item__content{
        padding:0;
    }
    .listPage /deep/ .van-cell__title{
        text-align: left;
    }
    .gridColor {
        color: #8F959E;
    }
    .infoPage {
        width: 100%;
        padding: 6px 16px 0 16px;
        box-sizing: border-box;
        font-size: 20px;
    }
    .infoBox {
        width: 100%;
    }
    .topBox {
        display: flex;
        align-items: center;
        width: 15%;
    }
    .textCla {
        // font-size: 13px;
        margin-left: 24px;
        color: #1F2329;
    }
    .bottomBox {
        margin-left: 0.45rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .dushed {
        border-left:1px dashed #3D7AEB;
        height: 60px;
    }
    .infoItem {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.8rem;
        box-sizing: border-box;
        background-color: #F8F9FA;
        width: 100%;
    }
    .arrow{
        color: #3D7AEB;
        cursor: pointer;
    }
    .arrow:hover {
      color: #12247F;
    }
    .textBox {
        font-size: 13px;
    }
    .topicCla {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        width: 35%;
        text-align: center;
    }
    .topicCla ._title{
        display: inline-block;
    }
    .topicCla  .textBlack {
       flex:1
    }
    .textBlack {
        color: #000;
    }
    .dateBox {
        margin-top: 0.5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 50%;
        box-sizing: border-box;
        padding-top: 0.4rem;
        height: 80px;
    }
    .dateCla {
        width: 46px;
        font-size: 20px;
        font-weight: bold;
    }
    .select {
        width: 46px;
        height: 46px;
        border-radius: 23px;
        display: flex;
        justify-content: center;
        align-items: center;
        /* padding-bottom: 1px; */
        background-color: #12247F;
        color: #fff;
    }
    .dateTop {
        font-size: 28px;
        font-weight: bold;
        color: #122766;
    }
    .topCla {
        width: 100%;
        background-color:#fff;
        padding: 1.2rem;
        box-sizing: border-box;
        border: 2px solid #E5EBFC;
    }
    .pointCla {
        width: 6px;
        height: 6px;
        margin-top: 0.5rem;
    }
    .blPoint {
        border-radius: 3px;
        background: #12247F;
    }
    .dateItem {
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
    }
    .special {
        margin-bottom: 8px;
        white-space:nowrap;
        overflow: hidden;
        text-overflow: ellipsis; 
    }
    .round {
      width: 14px;
      height: 14px;
      background-color: #3D7AEB;
      border-radius: 7px;
      margin-left: 1px;
    }
    .pcitem {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
    .w35 {
      width: 35%;
      text-align: center;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
}


</style>