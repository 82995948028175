import axios from 'axios';

axios.defaults.withCredentials = true
const http = axios.create({
  baseURL: process.env.VUE_APP_SERVE,
  timeout: 1000 * 1800,
  withCredentials: true
})

/**
 * 请求拦截
 */
http.interceptors.request.use(config => {
  return config
}, error => {
  return Promise.reject(error)
})

/**
 * 响应拦截
 */
http.interceptors.response.use(response => {
  return response
}, error => {
  return Promise.reject(error)
})

export default http
