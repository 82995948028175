<template>
  <div class="chairman">
    <div class="textTitle">欢迎辞</div>
    <div class="start">尊敬的各位同道：</div>
    <div>
      <p>
        人生海海，山山而川，不过尔尔。2022我们走过了很特别的一年，此刻的相聚，则尤显珍贵。回望来时路，所有的成绩都是无数神经病学领域前辈及同道们聚势前行的结果。才使得我国神经病学的发展跻身世界行列。中国神经病学领域的征途未有穷期，在医疗创新引领变革的今天，在规范临床学术指引前行的未来，我们即将迎来新的机遇与挑战。
      </p>
      <p>
        在见少年拉满弓，不惧岁月不惧风。时光镌刻记忆，见证领域变迁。中国神经病学论坛自开办以来，历经十五载，秉承“创新”、“合作”、“开放”的原则，坚持“倾听来自临床一线声音”的宗旨，汇聚了业内众多临床菁英。医者之匠心以传承，身披一袭白衣时刻铭记心中的誓言。以规范的临床诊疗，打造专业学术交流平台。实现多学科、跨维度、创领性的合作。
      </p>
      <p>
        中国神经病学领域的长久发展离不开传承，更需要创新。创新的技术驱动诊疗发展，前沿的学术推动临床实践。在此诚邀您参加2022年10月24日-29日于线上召开的上海卒中学会第三届学术年会暨第十六届中国神经病学论坛（CNF2022）及神经系统疾病·泛长三角联盟发布会，本次大会将带来丰富的学术活动。整合学术资源，云集业内大咖，群策群力创论学术，引领机遇与挑战并存下的中国神经病学临床的规范、创新与发展。
      </p>
      <p>
        希君生羽翼，一化北溟鱼。
      </p>
    </div>
    <div class="end">2022年9月</div>
    <div class="author">
      大会联合主席：
      <img src="../../../assets/signature.png" alt="大会联合主席" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Chairman"
}
</script>

<style scoped lang="less">
.chairman {
  background-color: rgba(255, 255, 255, 0.5);
  border: 1px solid #E5EBFC;
  padding: 35px 56px 18px;
  font-size: 22px;
  color: #727171;

  .textTitle {
    color: #122766;
    font-size: 38px;
    font-weight: bold;
    margin-bottom: 36px;
    text-align: center;
  }

  p {
    text-indent: 44px;
    margin: 0;
  }

  .start {
    margin-bottom: 22px;
  }

  .end {
    text-align: right;
    margin-top: 38px;
  }

  .author {
    line-height: 40px;
    margin-top: 19px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-right: -56px;

    & > img {
      width: 456px;
      margin-left: 29px;
    }
  }
}
</style>