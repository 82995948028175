<template>
  <div class="base">
    <slot />
  </div>
</template>

<script>
export default {
  name: "Base"
}
</script>

<style scoped>
.base {
  height: 100%;
  background: url('../assets/pcBC.png') center/cover no-repeat;
}

</style>