<template>
  <div class="committee">
    <div class="title">
      学术委员会
      <div class="subTitle">（排名不分先后）</div>
    </div>
    <img class="committeeImg" src="../../../assets/committee.png" alt="学术委员会" />
  </div>
</template>

<script>
import Base from "@/components/Base";
export default {
  name: "Structure",
  components: {
    Base
  }
}
</script>

<style scoped lang="less">
.committee {
  padding: 64px 79px 67px 84px;
  background-color: rgba(255, 255, 255, 0.5);
  border: 1px solid #E5EBFC;

  .title {
    font-size: 45px;
    color: #122766;
    font-weight: bold;
    text-align: center;
    margin-bottom: 42px;

    .subTitle {
      font-size: 25px;
      color: #122766;
      font-weight: bold;
    }
  }

  .committeeImg {
    width: 100%;
  }
}
</style>