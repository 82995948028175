<template>
  <div class="breadcrumb">
    您的位置:<span class="breadItem" @click="jump('home')">首页</span>>
    <div v-for="(item, index) of $props.routeList">
      <span :class="{
        'breadItem': item.to,
        'last': index === $props.routeList.length - 1
      }">
        {{item.text}}
      </span>
      <span v-if="index < $props.routeList.length - 1">></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Breadcrumb",
  props: {
    routeList: [
      {
        text: String,
        to: String
      }
    ]
  },
  methods: {
    jump (where) {
      this.$router.push({ name: where || 'plzWait' })
    }
  },
}
</script>

<style scoped lang="less">
.breadcrumb {
  display: flex;
  align-items: center;
  color: #4E4E4E;
  font-size: 24px;
  font-weight: 500;

  .breadItem {
    cursor: pointer;
  }

  .last {
    color: #122766;
  }
}
</style>