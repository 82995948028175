<template>
  <div class="structure">
    <div class="title">组织单位</div>
    <div class="content">
      <div class="subTitle">主办单位：</div>
      <div class="info">上海卒中学会</div>
      <div class="info">国家神经疾病医学中心（华山）</div>
      <div class="info">上海市脑卒中预防与救治服务体系</div>
      <div class="info">神经系统疾病·泛长三角联盟</div>

      <div class="subTitle" style="margin-top: 80px">协办单位</div>
      <div class="info">复旦大学附属华山医院</div>
      <div class="info">复旦大学附属中山医院</div>
      <div class="info">北京大学第三医院</div>
    </div>
  </div>
</template>

<script>
import Base from "@/components/Base";
export default {
  name: "Structure",
  components: {
    Base
  }
}
</script>

<style scoped lang="less">
.structure {
  padding: 35px 92px 285px 72px;
  background-color: rgba(255, 255, 255, 0.5);
  border: 1px solid #E5EBFC;

  .title {
    font-size: 38px;
    color: #122766;
    font-weight: bold;
    text-align: center;
    margin-bottom: 73px;
  }

  .content {
    .subTitle {
      font-size: 27px;
      color: #122766;
      font-weight: bold;
      line-height: 56px;
      border-bottom: solid 7px #0068B7;
    }

    .info {
      font-size: 27px;
      color: #6F6F6F;
      line-height: 56px;
      padding-left: 24px;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 24px;
        width: 7px;
        height: 7px;
        background-color: #535353;
        border-radius: 50%;
      }
    }
  }
}
</style>