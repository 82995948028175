<template>
  <Base>
    <div class="container">
      <Breadcrumb :route-list="[{ text: '联系我们' }]"></Breadcrumb>
      <div class="title">联系我们</div>
      <div class="content">
        <div class="subTitle">上海卒中学会学术会务部：</div>
        <div class="info">
          姓  名：褚老师
        </div>
        <div class="info">
          手  机：134 2636 0158
        </div>
        <div class="info">
          邮  箱：chuguoyan@shanghaistroke.org.cn
        </div>
        <div class="info">
          地  址：上海市静安区南京西路1486号东海广场3号楼908室
        </div>
        <div class="info">
          邮  编：200040
        </div>

        <div class="info" style="margin-top: 30px;">
          姓  名：沈老师
        </div>
        <div class="info">
          手  机：139 1715 3645
        </div>
        <div class="info">
          邮  箱：shengongshi@shanghaistroke.org.cn
        </div>
        <div class="info">
          地  址：上海市静安区南京西路1486号东海广场3号楼908室
        </div>
        <div class="info">
          邮  编：200040
        </div>

        <div class="subTitle" style="margin-top: 80px">ZOOM技术支持：</div>
        <div class="info" style="margin-top: 30px;">
          姓  名：张家申
        </div>
        <div class="info">
          手  机：159 9895 0297
        </div>
      </div>
    </div>
  </Base>
</template>

<script>
import Base from "@/components/Base";
import Breadcrumb from "@/components/Breadcrumb";
export default {
  name: "contact",
  components: {
    Breadcrumb,
    Base
  },
  methods: {
    jump (where) {
      this.$router.push({ name: where || 'plzWait' })
    }
  }
}
</script>

<style scoped lang="less">
.container {
  padding: 52px 0 259px;
  width: 1000px;
  margin: 0 auto;

  .title {
    font-size: 53px;
    color: #122766;
    margin: 83px 0 81px;
    font-weight: bold;
    text-align: center;
  }

  .content {
    .subTitle {
      font-size: 27px;
      color: #122766;
      font-weight: bold;
      line-height: 53px;
      padding-right: 7px;
      border-bottom: solid 7px #0068B7;
    }

    .info {
      font-size: 27px;
      color: #6F6F6F;
      line-height: 53px;
      padding-left: 24px;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 24px;
        width: 7px;
        height: 7px;
        background-color: #535353;
        border-radius: 50%;
      }
    }
  }
}
</style>