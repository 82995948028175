<template>
  <Base>
    <div class="container">
      <div class="content">
        <Breadcrumb
            :route-list="[
            { text: '会议信息' },
            { text: active === 'chairman' ? '大会主席' : '大会学术委员会' }
          ]"
        />
        <div class="tabs">
          <div class="left">
            <div :class="{ 'active': active === 'chairman', 'tabItem': true }" @click="handleActive('chairman')">大会主席</div>
            <div :class="{ 'active': active === 'committee', 'tabItem': true }" @click="handleActive('committee')">大会学术委员会</div>
          </div>
          <div class="right">
            <Chairman v-if="active === 'chairman'" />
            <Committee v-if="active === 'committee'" />
          </div>
        </div>
      </div>
    </div>

  </Base>
</template>

<script>
import Base from "@/components/Base";
import Breadcrumb from '@/components/Breadcrumb'
import Chairman from '@/views/People/rightContent/Chairman'
import Committee from '@/views/People/rightContent/Committee'
export default {
  name: "People",
  components: {
    Base,
    Breadcrumb,
    Chairman,
    Committee
  },
  data () {
    return {
      active: 'chairman'
    }
  },
  methods: {
    handleActive (value) {
      this.active = value
    }
  }
}
</script>

<style scoped lang="less">
.container {
  padding: 52px 0;

  .content {
    width: 1060px;
    margin: 0 auto;

    .tabs {
      display: flex;
      align-items: flex-start;
      margin-top: 52px;

      .left {
        width: 204px;
        height: 227px;
        border: solid 1px #D2D2D2;
        flex-shrink: 0;
        background-color: #ffffff;
        padding: 33px 7px 0 10px;
        margin-right: 10px;

        .tabItem {
          color: #727171;
          font-size: 24px;
          height: 34px;
          text-align: center;
          margin-bottom: 8px;
          cursor: pointer;

          &.active {
            background-color: #122766;
            border: solid 1px #000000;
            border-radius: 17px;
            color: #ffffff;
          }
        }
      }

      .right {
        flex: 1;
      }
    }
  }
}


</style>